<template>
    <div id="institucional" class="h-100">
        <Headerbar/>
        <b-container id="revendedoras" fluid>
            <b-row>
                <b-col>
                    <b-overlay :show="showLoading" rounded="sm">
                        <b-form id="form-buscar-revendedora" @submit="localizarRevendedora">
                            <h2>BUSCAR POR REVENDEDORAS</h2>

                            <!-- ESTADO -->
                            <b-form-group
                                id="input-group-estado"
                                label="UF"
                                label-for="estado"
                            >
                                <b-form-select id="estado" v-model="form.estado" required @change=" selectEstado() ">
                                    <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                    <b-form-select-option
                                        v-for="estado in revendedoresList"
                                        v-bind:key="estado.UF"
                                        :value=" estado.UF "
                                    >
                                        {{ estado.UF }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                            <!-- CIDADE -->
                            <b-form-group
                                id="input-group-cidade"
                                label="CIDADE"
                                label-for="cidade"
                            >
                                <b-form-select id="cidade" v-model="form.cidade" required @change=" selectCidade() ">
                                    <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                    <b-form-select-option
                                        v-for="cidade in this.cidades_disponiveis"
                                        v-bind:key="cidade.Cidade"
                                        :value=" cidade.Cidade "
                                    >
                                        {{ cidade.Cidade }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                            <!-- REVENDEDOR(A) -->
                            <b-form-group
                                id="input-group-revendedor"
                                label="REVENDEDOR(A)"
                                label-for="revendedor"
                            >
                                <b-form-select id="revendedor" v-model="form.revendedor" required>
                                    <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                    <b-form-select-option
                                        v-for="revendedor in this.revendedores_disponiveis"
                                        v-bind:key="revendedor.NomeLoja"
                                        :value=" revendedor.NomeLoja "
                                    >
                                        {{ revendedor.NomeRev }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                            <!-- SUBMIT -->
                            <div style=" display: flex; align-items: end; justify-content: center; ">
                                <b-button type="submit" variant="primary">BUSCAR</b-button>
                            </div>

                        </b-form>
                    </b-overlay>
                </b-col>
            </b-row>
            <b-row class="rodape" style="padding-top: 20px">
                <b-col class="d-flex align-items-center justify-content-end">
                    <b-img-lazy style="height: 20px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/anacapri-branco.png') "
                    ></b-img-lazy>
                </b-col>
                &bull;
                <b-col class="d-flex align-items-center justify-content-start">
                    <b-img-lazy style="height: 15px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/arezzo-branco.png') "
                    ></b-img-lazy>
                </b-col>
            </b-row>
            <b-row class="rodape" style="padding-bottom: 20px">
                <b-col class="text-center copyright">
                    Todos os direitos reservados Arezzo&Co
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<style lang="scss">
#revendedoras
{
    background-color: #E9E9E9;

    .b-overlay-wrap
    {
        height: 95%;

        *
        {
            font-family: "Montserrat", Arial, Helvetica, sans-serif !important;
        }

        form
        {
            border-radius: 40px;
            background-color: #F9F9F9;
            padding: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            height: 100%;
            display: grid;
            grid-template-rows: 50px 150px 150px 150px auto;

            h2
            {
                text-align: center;
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: bold;
                color: #707070;
            }

            select
            {
                border-radius: 20px;
                border: 1px solid #D6D6D6;
                box-shadow: 0 3px 5px #DEDEDE;
                height: 75px;
                padding: 20px;
            }

            button
            {
                background-color: #1EBCD5;
                border: 0;
                border-radius: 22px;
                color: white;
                font-weight: bold;
                font-size: 16px;
                width: 250px;
                box-shadow: 0 3px 5px #00000085;
                height: 50px;
            }
        }
    }

    .rodape
    {
        background-color: #1EBCD5;
        color: white;
        font-size: 20px;

        .copyright
        {
            margin-top: 3px;
            font-weight: 100;
            font-style: italic;
            text-align: center;
            font-size: 10px;
            font-family: Montserrat;
            letter-spacing: 0px;
            color: #FFFFFF;
        }

        .justify-content-end
        {
            padding-right: 5px;
        }

        .justify-content-start
        {
            padding-left: 5px;
        }

    }
}

@media (min-width: 767px)
{
    #revendedoras
    {
        display: grid;
        grid-template-rows: 100% 50px 50px;

        form
        {
            #estado
            {
                width: 300px;
            }
        }
    }
}
</style>
<script>

import Vue from 'vue'
import store from '@/store'
import '../../assets/css/institucional.scss'
import Headerbar from '../../components/site_institucional/headerbar'

export default {
    name: 'revendedoras',
    components: {Headerbar},
    props: [],
    data() {
        return {
            showLoading: true,
            revendedoresList: [],
            form: {
                estado: '',
                cidade: '',
                revendedor: '',
            },
            cidades_disponiveis: [],
            revendedores_disponiveis: [],
        }
    },
    methods: {
        resizeForm() {

            // Ajustar o app para 100%
            document.getElementById('app').style.height = '100%'

            // Calibrar area disponivel
            const windowSize = window.innerHeight
            const headerSize = document.getElementById('header').clientHeight
            document.getElementById('revendedoras').style.minHeight = (windowSize - headerSize).toString().concat('px')
        },
        GetResellerInfo() {
            store.dispatch('getpesquisarevendedora')
                .then((response) => {

                    this.revendedoresList = response

                    this.showLoading = false
                })
                .catch((error) => {
                    console.log('error', error)
                })
        },
        localizarRevendedora(evt) {
            evt.preventDefault()

            if (Vue.lodash.isEmpty(this.form.revendedor) === false) {
                this.$router.push(`/${this.form.revendedor}/`)
            }

        },
        selectEstado() {
            let estado_selecionado = Vue.lodash.find(this.revendedoresList, estado => {
                return estado.UF === this.form.estado;
            });

            if (Vue.lodash.isEmpty(estado_selecionado)) {
                return []
            }

            this.form.cidade = '';
            this.cidades_disponiveis = estado_selecionado.Cidade;
            this.form.revendedor = '';
            this.revendedores_disponiveis = [];
        },
        selectCidade() {
            let cidade_selecionada = Vue.lodash.find(this.cidades_disponiveis, cidade => {
                return cidade.Cidade === this.form.cidade;
            });

            if (Vue.lodash.isEmpty(cidade_selecionada)) {
                return []
            }

            this.form.revendedor = '';
            this.revendedores_disponiveis = cidade_selecionada.Revendedoras;
        }
    },
    mounted() {
        document.title = 'BUSCAR POR REVENDEDORA'
        this.GetResellerInfo()
        this.resizeForm()
    },
}
</script>
